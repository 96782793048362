
		<!-- remove this if you use Modernizr -->
		<script>(function(e,t,n){var r=e.querySelectorAll("html")[0];r.className=r.className.replace(/(^|\s)no-js(\s|$)/,"$1js$2")})(document,window,0);</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Iscrizione alla piattaforma</li>
            </ul>
            <h2>Iscrizione alla piattaforma</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <img src="assets/img/iscrizione_piattaforma.jpg"  style="max-height:550px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5" [ngClass]="{'d-none': idCorsistaDaAttivare!=''}">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info d-none">
                    <span class="sub-title">Chi siamo</span>
                    <h2>La Nostra Storia</h2>
                    <p class="d-none">Centro di formazione accreditato dalla Regione Campania, promuove corsi e master altamente qualificanti in ogni settore. Il nostro intento è quello di creare, adeguare, mantenere e modificare nel tempo le competenze e le specializzazioni professionali, in virtù delle sempre diverse esigenze di un mercato dinamico ed in continua evoluzione; perseguiamo fermamente il fine di qualificare e soprattutto abbreviare la distanza tra formazione ed inserimento lavorativo.</p>
                    <p><b>laformazioneadistanza.it</b> nasce dall’esperienza di giovani professionisti nel settore della formazione professionale che hanno realizzato questa piattaforma per la formazione a distanza specializzandosi con i corsi per la sicurezza sugli ambienti e sui luoghi di lavoro Testo Unico D.Lgs. 81/08 (Ex D.Lgs. 626/94), per i corsi di alimentarista (ex libretto sanitario) Regolamenti Comunitari n. 852/04 e 853/04 e per le abilitazioni/qualifiche dei repertori Regionali </p>
                    <p>
                        <b>La Nostra Mission</b>
                        L’intento della laformazioneadistanza.it è quello di creare, adeguare, mantenere e modificare nel tempo le competenze e le specializzazioni professionali, in virtù delle sempre diverse esigenze di un mercato dinamico ed in continua evoluzione; perseguiamo fermamente il fine di qualificare e soprattutto abbreviare la distanza tra formazione ed inserimento lavorativo.
                    </p>
                </div>

                <div class="row mt-5">
                    <div class="col-lg-12 col-md-12">
                        <div class="contact-info">
                            <span class="sub-title">Informazioni</span>
                            <h2>Mettiti in contatto</h2>
                            <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                            <ul>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-map'></i>
                                    </div>
                                    <h3>Dove siamo</h3>
                                    <p>Via XI Settembre 2001, 32 bis<br>80043 Mariglianella (NA)</p>
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-phone-call'></i>
                                    </div>
                                    <h3>Contatti</h3>
                                    <p>Mobile: <a href="tel:+3908118476029">(+39) 081-18476029</a></p>
                                    <p>e-mail: <a href="mailto:info@pragmaformazione.it">info@pragmaformazione.it</a></p>
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class='bx bx-time-five'></i>
                                    </div>
                                    <h3>Siamo disponibili</h3>
                                    <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                                    <p>previo appuntamento</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Compila il form con i tuoi dati</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome*">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="cognome" type="text" #cognome="ngModel" class="form-control" id="cognome" placeholder="Cognome*">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select ngModel #sesso="ngModel" name="sesso" id="sesso" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="maschio">maschio</option>
                                        <option value="femmina">femmina</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input ngModel #cf="ngModel" name="cf" type="text" class="form-control" id="cf" placeholder="codice fiscale*">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input ngModel #indirizzo="ngModel" name="indirizzo" type="text" class="form-control" id="indirizzo" placeholder="indirizzo di residenza">
                                </div>
                            </div>
                            <div class="row" style="padding-right: 0px;">
                                <div class="col-lg-3 pe-0">
                                    <div class="form-group">
                                        <input ngModel #cap="ngModel" name="cap" type="text" class="form-control" id="cap" placeholder="CAP">
                                    </div>
                                </div>
                                <div class="col-lg-5 pe-0">
                                    <div class="form-group">
                                        <input ngModel #citta="ngModel" name="citta" type="text" class="form-control" id="citta" placeholder="città">
                                    </div>
                                </div>
                                <div class="col-lg-4 pe-0" style="padding-right:0px">
                                    <div class="form-group">
                                        <input ngModel #provincia="ngModel" name="provincia" type="text" class="form-control" id="provincia" placeholder="provincia">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="100" ngModel #luogoNascita="ngModel" name="luogoNascita" type="text" class="form-control" id="luogoNascita" placeholder="luogo di nascita*">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Luogo di nascita richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 100 caratteri.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel id="dataNascita" #dataNascita="ngModel" name="dataNascita" type="date" style="padding-right:10px">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail*">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare*">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>

                            <h2 *ngIf="qrCode!='nuovo-account'" class="mt-3">Quale corso sei interessato?</h2>
                            <p *ngIf="qrCode!='nuovo-account'">seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div *ngIf="qrCode!='nuovo-account'" class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select ngModel #sceltaCorso="ngModel" name="sceltaCorso" id="sceltaCorso" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['id_corso']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>

                            <h2 class="mt-3">Allega un documento di riconoscimento</h2>
                            <p>questo punto è molto importante, senza un documento valido la richiesta non puà essere ritenuta valida*</p>

                            <div class="col-lg-12 col-md-6 d-none">
                                <div class="form-group">
                                    <input type="file" style="background-color:transparent; margin-top: 15px; padding-left:0px"  (change)="onFileSelected($event)" #fileUpload>
                                </div>
                            </div>

                            <div class="box">
                                <input type="file" name="file-2[]" id="file-2" class="inputfile inputfile-2 d-none" (change)="onFileSelected($event)" data-multiple-caption="{count} files selected" multiple />
                                <label for="file-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/></svg>
                                    <span id="fileNameChange" class="ms-2">Selezione il file da inviare&hellip;</span>
                                </label>
                            </div>

                            <div class="col-lg-12 col-md-6 mt-3">
                                <div class="form-group">
                                    <h2 class="mt-3">Decreto sulla privacy</h2>
                                    <p>
                                        Compilando il seguente modulo autorizza l’utilizzo dei suoi dati personali secondo il Regolamento Privacy 2016/679/UE (Regolamento generale sulla protezione dei dati - di seguito GDPR).
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 mt-3">
                                <button *ngIf="qrCode!='nuovo-account'" type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                                <button *ngIf="qrCode=='nuovo-account'" type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Crea l'account per Discentya<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="row mb-5" [ngClass]="{'d-none': idCorsistaDaAttivare==''}">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-info">
                        <span class="sub-title">ATTIVAZIONE CORSISTA</span>
                        <h2 *ngIf="isAddedCorsista=='1'">il corsista <span class="text-capitalize">{{nomeCognomeCorsista}}</span> è stato attivato e inserito nel corso: <span class="text-uppercase">{{titoloCorso}}</span></h2>
                        <h2 *ngIf="isAddedCorsista=='0'">Errore: non è possibile attivare e aggiungere il corsista nel corso: <span class="text-uppercase">{{titoloCorso}}</span></h2>
                        <p *ngIf="isAddedCorsista=='1'">
                            <b>Notifiche: </b>
                            il corsista ha ricevuto tramite indirizzo e-mail tutte le informazioni per l'accesso al corso, con il proprio <b>username</b> e <b>password</b>.
                        </p>
                        <p *ngIf="isAddedCorsista=='0'">
                            <b>Notifiche: </b>
                            è stato riscontrato un errore durante la procedura automatica di attivazione ed inserimento nel corso del corsista, contattare l'assistenza per ulteriori informazioni in mertito. <b>Verificare che il corsista non sia già presente nel corso</b>.
                        </p>
                    </div>
                </div>
        </div>

    </div>
</div>

<app-learning-banner></app-learning-banner>

<app-features-style-four></app-features-style-four>

<app-learning-courses></app-learning-courses>

<app-learning-about></app-learning-about>

<app-testimonials-style-two></app-testimonials-style-two>

<app-get-instant-courses-two></app-get-instant-courses-two>

<div class="partner-area ptb-100">
    <div class="container">
        <app-partner-slider></app-partner-slider>
    </div>
</div>

<app-video-style-two></app-video-style-two>

<app-funfacts-style-four></app-funfacts-style-four>

<app-learning-blog></app-learning-blog>

<app-learning-view-courses></app-learning-view-courses>

<app-learning-premium-access></app-learning-premium-access>
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        PRAGMA FORMAZIONE S.r.l.s. è un Ente di Formazione Professionale che nasce dalla volontà di dare un supporto valido alle persone che intendono entrare nel mondo del lavoro e alle persone già inserite che vogliono migliorare le loro competenze.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/pragmaformazione" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/pragma_formazione/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://discentya.pragmaformazione.com/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://discentya.pragmaformazione.com/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via XI Settembre 2001, 32 bis<br>80030 Mariglianella (Na) - Italia</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+3908118476029">Telefono: (+39) 081-18476029</a></li>
                        <li><i class='bx bxl-whatsapp'></i><a href="tel:+3792739752">WhatsApp: (+39) 379-2739752</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@pragmaformazione.it">info@pragmaformazione.it</a></li>
                        <li>
                            <i class='bx bxs-inbox'></i><a href="tel:+3908118476029">Lunedì-Venerdì: 09:00÷18:00</a>
                            <br>previo appuntamento
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2023 Pragma Formazione - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a href="https://www.pragmaformazione.it/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
